// ControlsHeader.js
import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./ControlsHeader.module.scss"
import { Link, useNavigate } from "react-router-dom"
import { DotsLoader } from "../../ui/Loader"
import cn from "classnames"
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite"
import { filterControlsFn } from "../../functions/filterControlsFn"
import { Search } from "../../ui/Search/Search"
import { axiosInstance } from "../../config/https"

const ControlsHeader = ({
	frameworks,
	selectedFilter,
	setSelectedFilter,
	onSelectFramework,
	activeTab,
	setAddFramework,
	users,
	filterList,
	setFilterList,
	setSortControls,
	controls,
	sortControls,
	search,
	setSearch,
	setSearchData,
	searchData,
}) => {
	const navigate = useNavigate()
	const dropdownRef = useRef(null)
	const dropdownRefChild = useRef(null)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [articleNumbers, setArticleNumbers] = useState([])

	const getArticleNumbers = useCallback(() => {
		if (activeTab !== "all-controls" && activeTab !== "non-applicable") {
			axiosInstance.get(`/frameworks/${activeTab}/article_numbers`).then(res => {
				setArticleNumbers(res.data)
			})
		}
	}, [activeTab])

	const handlePrev = () => {
		setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0))
	}

	const handleNext = () => {
		setCurrentIndex(prevIndex => Math.min(prevIndex + 1, frameworks.length - 5))
	}

	const [open, setOpen] = useState({
		filter: false,
		control_function: false,
		submission_stakeholders: false,
		submission_owner: false,
		article_numbers: false,
	})
	const [label] = useState({
		control_function: "Function",
		submission_stakeholders: "Assignee",
		submission_owner: "Control owner",
		article_numbers: "Articles",
	})

	const Filters = {
		control_function: ["Identify", "Protect", "Recover", "Respond", "Detect"].map((f, i) => ({ id: i, name: f })),
		submission_stakeholders: users?.map(u => ({ id: u.id, name: u.firstName + " " + u.lastName })),
		submission_owner: users?.map(u => ({ id: u.id, name: u.firstName + " " + u.lastName })),
		article_numbers: articleNumbers.map((f, i) => ({ id: f, name: f })),
	}

	const handleFilterSelection = filter => {
		if (selectedFilter?.includes(filter)) {
			setSelectedFilter([...selectedFilter.filter(s => s !== filter)])
			setFilterList(prevState => ({
				...prevState,
				[filter]: [],
			}))
		} else {
			setSelectedFilter([...selectedFilter, filter])
		}
	}

	const handleCreateFilterList = (filterLabel, filterItem) => {
		setFilterList(prevState => {
			const currentItems = prevState[filterLabel] || []
			const newItem = filterLabel === "control_function" ? filterItem.name : filterItem.id

			if (currentItems?.includes(newItem)) {
				return {
					...prevState,
					[filterLabel]: currentItems.filter(item => item !== newItem),
				}
			} else {
				return {
					...prevState,
					[filterLabel]: [...currentItems, newItem],
				}
			}
		})
	}

	const openDropDown = (item, value) => {
		setOpen(prevState => ({
			...prevState,
			[item]: value,
		}))
	}

	const handelFilter = () => {
		setSortControls(filterControlsFn(controls, filterList))
		setOpen({
			filter: false,
			control_function: false,
			submission_stakeholders: false,
			submission_owner: false,
			article_numbers: false,
		})
		localStorage.setItem("controls-filter", JSON.stringify(filterList))
	}

	const handelRemoveFilters = filter => {
		setSelectedFilter(selectedFilter.filter(f => f !== filter))

		setFilterList(prevState => {
			const updatedFilterList = {
				...prevState,
				[filter]: [],
			}

			setSortControls(filterControlsFn(controls, updatedFilterList))
			localStorage.setItem("controls-filter", JSON.stringify(updatedFilterList))

			return updatedFilterList
		})
	}

	useEffect(() => {
		setSearchData(sortControls?.filter(c => 
			c.control_name.toLowerCase().includes(search.toLowerCase()) ||
			(c.control_description && c.control_description.toLowerCase().includes(search.toLowerCase()))
		))
		localStorage.setItem("control-search", search)
		getArticleNumbers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, getArticleNumbers])

	const getFilterDisplayText = (filterLabel, filterItems) => {
		if (!filterItems.length) return `Select ${label[filterLabel]}`

		const displayItems = filterItems.slice(0, 2).map(item => {
			if (filterLabel === "control_function") {
				return item
			} else {
				const user = users?.find(u => u.id === item)
				return user ? user.firstName + " " + user.lastName : item
			}
		})

		return `${displayItems.join(", ")}${filterItems.length > 2 ? "..." : ""}`
	}

	const handelBulk = () => {
		var name =
			activeTab === "all-controls"
				? "All Controls"
				: activeTab === "non-applicable"
					? "Non applicable"
					: frameworks?.find(f => f.id === parseInt(activeTab))?.name
		navigate("/controls/actions", { state: { id: activeTab, name: name, filter: filterList } })
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			openDropDown("filter", false)
		}
		if (dropdownRefChild.current && !dropdownRefChild.current.contains(event.target)) {
			setOpen(prevState => {
				return {
					...prevState,
					control_function: false,
					submission_stakeholders: false,
					submission_owner: false,
					Articles: false,
				}
			})
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.header}>
			<div className={styles.tabContainer}>
				{frameworks?.length ? (
					<Link
						to="/controls/all-controls"
						onClick={() => onSelectFramework("all-controls")}
						className={cn(styles.tab, {
							[styles.active]: activeTab === "all-controls",
							[styles.specialTab]: frameworks?.length > 5,
						})}
					>
						All Controls
					</Link>
				) : (
					""
				)}
				{frameworks?.length ? (
					<Link
						to="/controls/non-applicable"
						onClick={() => onSelectFramework("non-applicable")}
						className={cn(styles.tab, {
							[styles.active]: activeTab === "non-applicable",
							[styles.specialTab]: frameworks?.length > 5,
						})}
					>
						Non Applicable
					</Link>
				) : (
					""
				)}
				{frameworks &&
					frameworks?.length <= 5 &&
					frameworks?.map(framework => (
						<Link
							key={framework.id}
							onClick={() => onSelectFramework(framework.id)}
							to={`/controls/${framework.id}`}
							className={cn(styles.tab, { [styles.active]: parseInt(activeTab) === framework.id })}
						>
							{framework.name}
						</Link>
					))}
				{frameworks && frameworks.length > 5 && (
					<div className={styles.carouselContainer}>
						<button
							className={styles.navButton}
							onClick={handlePrev}
							disabled={currentIndex === 0}
						>
							&lt;
						</button>
						<div className={styles.carousel}>
							{frameworks.slice(currentIndex, currentIndex + 5).map(framework => (
								<Link
									key={framework.id}
									onClick={() => onSelectFramework(framework.id)}
									to={`/controls/${framework.id}`}
									className={cn(styles.tab, { [styles.active]: parseInt(activeTab) === framework.id })}
								>
									{framework.name}
								</Link>
							))}
						</div>
						<button
							className={styles.navButton}
							onClick={handleNext}
							disabled={currentIndex === frameworks.length - 5}
						>
							&gt;
						</button>
					</div>
				)}
				{frameworks?.length ? (
					<button
						className={styles.addBtn}
						onClick={() => {
							setAddFramework(true)
						}}
						disabled={!(frameworks?.length >= 0).toString()}
					>
						Add Framework
					</button>
				) : (
					""
				)}
				{!frameworks?.length && (
					<div className={styles.DotsLoader}>
						{" "}
						<DotsLoader />{" "}
					</div>
				)}
			</div>
			<div className={styles.tabContainer}>
				<div className={styles.section3}>
					<div>{(search?.length > 0 ? searchData : sortControls)?.length} Controls</div>
					<button
						className={styles.bulkActionBtn}
						onClick={handelBulk}
					>
						Bulk Actions <SvgSprite spriteID={"ok"} />
					</button>
				</div>
				<Search
					value={search}
					setSearch={setSearch}
					className={styles.controlsSearch}
					placeholder={"Search in title or description"}
				/>

				<div className={styles.section1}>
					<div className={styles.filterTable}>
						{selectedFilter?.map(f => (
							<div
								key={f}
								className={styles.filterTableContents}
							>
								<div className={styles.BtnFilters}>{label[f]}</div>
								<div
									key={`#${f}`}
									className={styles.filterContainer}
								>
									<button
										className={cn(styles.BtnFilters, { [styles.activeFilterList]: filterList[f]?.length > 0 })}
										onClick={() => openDropDown(f, !open[f])}
									>
										<div>{getFilterDisplayText(f, filterList[f])}</div>
										<SvgSprite
											spriteID={"arrow"}
											className={styles.filterIconArrow}
										/>
									</button>
									{open[f] && (
										<div
											className={styles.selectFilter}
											ref={dropdownRefChild}
										>
											{Filters[f]?.map(filter => (
												<div
													key={`#${filter.id}`}
													value={filter.name}
													onClick={() => handleCreateFilterList(f, filter)}
													className={cn(styles.itemFilter, {
														[styles.activeFilter]: filterList[f]?.includes(
															f === "control_function" ? filter.name : filter.id,
														),
													})}
												>
													{filter.name}
												</div>
											))}
										</div>
									)}
								</div>
								<SvgSprite
									spriteID={"trash-del"}
									className={styles.filterIconTrash}
									onClick={() => handelRemoveFilters(f)}
								/>
							</div>
						))}
						<div className={styles.filterTableContentsApply}>
							<div className={styles.filterText}>
								<SvgSprite
									spriteID={"filter-controls"}
									className={styles.filterIcon}
								/>
								<button
									className={styles.itemFilter}
									onClick={() => openDropDown("filter", !open["filter"])}
								>
									<SvgSprite
										spriteID={"plus"}
										className={styles.filterIconArrow}
									/>
									Filters
								</button>
								{open["filter"] && (
									<div
										className={styles.selectFilter}
										ref={dropdownRef}
									>
										{Object.keys(Filters).map(filter => (
											<div
												key={filter}
												value={filter}
												onClick={() => {
													handleFilterSelection(filter)
													openDropDown("filter", !open["filter"])
												}}
												className={cn(styles.itemFilter, { [styles.activeFilter]: selectedFilter.includes(filter) })}
											>
												{label[filter]}
											</div>
										))}
									</div>
								)}
							</div>
							{Object.values(selectedFilter).some(value => value) && (
								<button
									className={styles.BtnApply}
									onClick={handelFilter}
								>
									Apply{" "}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ControlsHeader
