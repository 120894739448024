import React from "react"
import { SvgSprite } from "../../../ui/SvgSprite"
import { Loader } from "../../../ui/Loader"
import cn from "classnames"
import styles from "../index.module.scss"

export const ResourceItem = ({
	resource,
	isFolder,
	dropResource,
	setDropResource,
	resourceRef,
	actions,
	onAction,
	deleteLoad,
	onFileClick
}) => {
	return (
		<div className={cn(styles.gridItem)}>
			<div className={styles.resourcesHeaderIcon}>
				<SvgSprite
					spriteID={"dots"}
					className={cn(styles.resourcesIcon, styles.dotsStyles)}
					onClick={() => setDropResource(dropResource === resource?.id ? null : resource?.id)}
				/>
				{dropResource === resource?.id && (
					<div
						ref={resourceRef}
						className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
					>
						{actions?.map((action, index) => (
							<div
								key={index}
								className={cn(styles.dropResourceBtn, {
									[styles.deleteLoad]: action.type === 'delete' && deleteLoad,
								})}
								onClick={() => onAction(action.type, resource)}
							>
								<div>{action.label}</div>
								{action.type === 'delete' && deleteLoad ? (
									<Loader />
								) : (
									<SvgSprite
										spriteID={action.icon}
										className={styles.resourcesIcon}
									/>
								)}
							</div>
						))}
					</div>
				)}
			</div>
			<div
				className={styles.bodyTypeContainer}
				onClick={(e) => {
					if (isFolder) {
						onAction('select', resource);
					} else {
						onFileClick?.(e);
					}
				}}
			>
				{isFolder ? (
					<SvgSprite
						spriteID={"folder"}
						className={styles.folderStyles}
					/>
				) : (
					<div className={styles.extention}>{resource.extension}</div>
				)}
			</div>
			<div
				className={styles.centerFrameworkName}
				onClick={(e) => {
					if (isFolder) {
						onAction('select', resource);
					} else {
						onFileClick?.(e);
					}
				}}
				title={resource.name}
			>
				{resource.name}
			</div>
			{!isFolder && (
				<div
					className={styles.frameworkCategory}
					onClick={(e) => {
						onFileClick?.(e);
					}}
				>
					{resource.category}
				</div>
			)}
		</div>
	);
}
