import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getResources } from "../../functions/getResources";
import { DotsLoader, Loader } from "../../ui/Loader";
import { SvgSprite } from "../../ui/SvgSprite";
import { axiosInstance } from "../../config/https";
import { Btn } from "../../ui/Btn";
import { Input } from "../../ui/Input/Input";
import { useInput } from "../../hooks/useInput";
import { TextEditor } from "../../ui/TextEditor";
import { toast } from "../../functions/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { pdfExporter } from "quill-to-pdf";
import { Popup } from "../../ui/Popup";
import { Repository } from "./Repository";
import { useResourceSearch } from "./hooks/useResourceSearch";
import { ResourceHeader } from "./components/ResourceHeader";
import { ResourceItem } from "./components/ResourceItem";
import { v4 as uuidv4 } from "uuid";

export const Resources = () => {
  const resources = useSelector(state => state.resources.resources);
  const [data, setData] = useState([]);
  const [createResource, setCreateResource] = useState(false);
  const dispatch = useDispatch();
  const [activeResource, setActiveResource] = useState(0);
  const [resource, setResource] = useState();
  const title = useInput("");
  const [pdfPreview, setPdfPreview] = useState(false);
  const location = useLocation();
  const [error, setError] = useState({ title: false, pdf: false });
  const editorRef = useRef(null);
  const [loadSave, setLoadSave] = useState(false);
  const [html, setHtml] = useState(null);
  const [update, setUpdate] = useState(false);
  const [dropResource, setDropResource] = useState(null);
  const navigate = useNavigate();
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [showRepository, setShowRepository] = useState(false);
  const [repositoryName, setRepositoryName] = useState("");
  const [repositoryLoader, setRepositoryLoader] = useState(false);
  const [listRepositories, setListRepositories] = useState([]);
  const [activeRepository, setActiveRepository] = useState(null);
  const [showListRepositoriesPopup, setShowListRepositoriesPopup] = useState(false);
  const [moveToRepository, setMoveToRepository] = useState(null);
  const [listOfRepositoryResources, setListOfRepositoryResources] = useState([]);
  const [resourcesLoad, setResourcesLoad] = useState(false);
  const [dropFolder, setDropFolder] = useState(null);
  const [deleteLoadFolder, setDeleteLoadFolder] = useState(false);
  const [showDeleteRepository, setShowDeleteRepository] = useState(null);
  const dropdownRef = useRef(null);
  const [uploadResources, setUploadResources] = useState(false);
  const [uploadResourcesLoad, setUploadResourcesLoad] = useState(false);
  const [createRepositoryLoader, setCreateRepositoryLoader] = useState(false);
  const [listOfRepositoryChildren, setListOfRepositoryChildren] = useState([]);
  const [path, setPath] = useState([]);
  const [allRepositories, setAllRepositories] = useState([]);
  const [moveRepToRep, setMoveRepToRep] = useState(null);
  const [showRenameRepository, setShowRenameRepository] = useState(null);
  const [newRepositoryName, setNewRepositoryName] = useState("");
  const [renameLoader, setRenameLoader] = useState(false);
  const { searchResults, isSearching, searchResources, clearSearch } = useResourceSearch(dispatch);
  const ressourcesRef = useRef(null);
  const [uploadResourceFiles, setUploadResourceFiles] = useState([]);

  const resourceActions = [
    {
      type: "clone",
      label: "Clone",
      icon: "filePaper",
      onClick: resource => {
        cloneResource(resource);
        setDropResource(null);
      },
      showIf: resource => resource?.html,
    },
    {
      type: "edit",
      label: "Edit",
      icon: "edit_pen",
      onClick: resource => {
        editResource(resource);
        setDropResource(null);
      },
      showIf: resource => resource?.organization_id && resource?.html,
    },
    {
      type: "download",
      label: "Download",
      icon: "download",
      onClick: resource => {
        downloadFile(resource.policyDocument);
        setDropResource(null);
      },
    },
    {
      type: "move",
      label: "Move",
      icon: "move",
      onClick: resource => {
        setShowListRepositoriesPopup(resource.id);
        setDropResource(null);
      },
    },
    {
      type: "delete",
      label: "Delete",
      icon: "trash",
      onClick: resource => delete_resource(resource?.id),
      showIf: resource => resource?.organization_id,
    },
  ];

  useEffect(() => {
    if (activeRepository && path[path.length - 1]?.id !== activeRepository.id) {
      setPath([...path, activeRepository]);
    } else if (!activeRepository) {
      setPath([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRepository]);

  useEffect(() => {
    if (location.state) {
      setCreateResource(true);
      setResource(location.state);
      window.history.replaceState({}, document.title, location.pathname); // Reset the location state
    }
  }, [location]);

  useEffect(() => {
    setError({ title: false, pdf: false });
    // title.setNewValue('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResource]);

  const changeResources = n => {
    setActiveResource(n);
  };

  const saveResource = () => {
    try {
      setLoadSave(true);
      if (title.value.length <= 0 || !resource || resource === "<p><br></p>") {
        setError({ title: title.value.length <= 0, pdf: !(resource && resource !== "<p><br></p>") });
        setLoadSave(false);
        return;
      }
      handleGeneratePdf(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGeneratePdf = async (n = true) => {
    const delta = editorRef.current?.editor?.getContents();
    const pdfAsBlob = await pdfExporter.generatePdf(delta);

    if (n) {
      var blobURL = URL.createObjectURL(pdfAsBlob);
      window.open(blobURL, "_blank", "height=650,width=840");
    } else {
      const dataQuery = {
        titleResource: title.value,
        resource: pdfAsBlob,
        html: resource,
        activeRepository: activeRepository?.id,
      };
      const headers = {
        "content-type": "multipart/form-data",
      };
      axiosInstance
        .post("/resources", dataQuery, { headers })
        .then(async res => {
          activeRepository
            ? setListOfRepositoryResources([...listOfRepositoryResources, res.data.resource])
            : setData([...data, res.data.resource]);
          dispatch(toast("success", "Resource Created Successfully"));
        })
        .catch(err => dispatch(toast("error", err?.response?.data?.message)))
        .finally(() => {
          setCreateResource(false);
          setLoadSave(false);
          setResource("");
          setHtml("");
          title.setNewValue("");
        });
    }
  };

  const getExt = path => {
    if (!path) return "";
    try {
      return path.substring(path.lastIndexOf(".") + 1);
    } catch (err) {
      console.error("Error getting file extension:", err);
      return "";
    }
  };

  useEffect(() => {
    if (activeResource) {
      setData(resources?.filter(item => item.organization_id === null));
    } else {
      setData(resources?.filter(item => item.organization_id !== null));
    }
  }, [resources, activeResource]);

  const getFile = async path => {
    try {
      if (!path) {
        dispatch(toast("error", "No file path provided"));
        return;
      }
      
      const res = await axiosInstance(`download/resources/${path}`, { 
        responseType: "blob",
        headers: {
          'Accept': '*/*'
        }
      });
      
      const contentType = res.headers["content-type"];
      const blob = new Blob([res.data], { type: contentType });

      if (contentType === "application/pdf") {
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, "_blank", "height=650,width=840");
        setTimeout(() => URL.revokeObjectURL(blobURL), 100);
      } else {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = path.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      dispatch(toast("error", err?.response?.data?.message || err?.message || "Error accessing file"));
    }
  };

  const downloadFile = async path => {
    axiosInstance(path, { responseType: "blob" })
      .then(async res => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const fileName = path.substring(path.lastIndexOf("/") + 1);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .catch(err => {
        dispatch(toast("error", err?.message));
      });
  };

  useEffect(() => {
    (async () => {
      await Promise.all([!resources && dispatch(getResources())]);
    })();
  }, [dispatch, resources]);

  const cloneResource = resource => {
    setCreateResource(true);
    setHtml(resource?.html);
  };

  const editResource = resource => {
    setCreateResource(true);
    setHtml(resource?.html);
    title.setNewValue(resource?.policyName);
    setUpdate(resource?.id);
  };

  const updateResource = async () => {
    const delta = editorRef.current?.editor?.getContents();
    const pdfAsBlob = await pdfExporter.generatePdf(delta);
    setLoadSave(true);

    const dataQuery = {
      titleResource: title.value,
      resource: pdfAsBlob,
      html: resource,
    };
    const headers = {
      "content-type": "multipart/form-data",
    };
    axiosInstance
      .post(`/update/resources/${update}`, dataQuery, { headers })
      .then(res => {
        if (activeRepository) {
          setListOfRepositoryResources(prevResources => [
            ...prevResources.filter(r => r.id !== update),
            res.data.resource,
          ]);
        } else {
          setData(prevData => [...prevData.filter(r => r.id !== update), res.data.resource]);
        }
        setCreateResource(false);
        setResource("");
        setHtml("");
        title.setNewValue("");
      })
      .catch(err => dispatch(toast("error", err?.response?.data?.message)))
      .finally(() => {
        setCreateResource(false);
        setLoadSave(false);
        setUpdate(false);
      });
  };

  const delete_resource = id => {
    if (!deleteLoad) {
      setDeleteLoad(true);
      axiosInstance
        .delete(`/delete/resources/${id}`)
        .then(res => {
          dispatch(toast("success", res.data.message));
          setData(data?.filter(r => r.id !== id));
        })
        .catch(err => {
          dispatch(toast("error", err?.response?.data?.message));
        })
        .finally(() => {
          setDeleteLoad(false);
        });
    }
  };

  const handelGenerateRepository = () => {
    const data = {
      name: repositoryName,
      parent_id: activeRepository?.id,
    };
    setCreateRepositoryLoader(true);
    axiosInstance
      .post("/repositories", data)
      .then(res => {
        if (activeRepository) {
          setListOfRepositoryChildren([...listOfRepositoryChildren, res.data.repository]);
        } else {
          setListRepositories([...listRepositories, res.data.repository]);
        }
        setAllRepositories([...allRepositories, res.data.repository]);
        dispatch(toast("success", "Repository Created Successfully"));
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
            dispatch(toast("error", `${key}: ${value}`));
          }
        } else {
          dispatch(toast("error", err?.response?.data?.message));
        }
      })
      .finally(() => {
        setCreateRepositoryLoader(false);
        setShowRepository(false);
      });
  };

  const getRepository = useCallback(async () => {
    setRepositoryLoader(true);
    await axiosInstance("/repositories")
      .then(res => {
        setListRepositories([...res.data.data]);
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => setRepositoryLoader(false));
  }, [dispatch]);

  useEffect(() => {
    getRepository();
  }, [getRepository]);

  const getAllRepositories = useCallback(() => {
    axiosInstance("/all/repositories")
      .then(res => {
        setAllRepositories([...res.data.data]);
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      });
  }, [dispatch]);

  useEffect(() => {
    getAllRepositories();
  }, [getAllRepositories]);

  const handelMove = () => {
    try {
      setCreateRepositoryLoader(true);
      const dataQuery = {
        repository_id: moveToRepository,
        resource_id: showListRepositoriesPopup,
      };
      axiosInstance
        .patch("repositories", dataQuery)
        .then(() => {
          if (activeRepository) {
            var resource = listOfRepositoryResources.find(r => r.id === showListRepositoriesPopup);
            setListOfRepositoryResources([
              ...listOfRepositoryResources?.filter(r => r.id !== showListRepositoriesPopup),
            ]);
            setData([...data, resource]);
          } else {
            setData([...data?.filter(r => r.id !== showListRepositoriesPopup)]);
          }
          dispatch(toast("success", "Resource Moved Successfully"));
        })
        .catch(err => {
          console.log(err);
          dispatch(toast("error", err?.response?.data?.message));
        })
        .finally(() => {
          setMoveToRepository(null);
          setShowListRepositoriesPopup(false);
          setCreateRepositoryLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getRepositoryResources = () => {
    setResourcesLoad(true);
    axiosInstance(`repositories/${activeRepository?.id}`)
      .then(res => {
        setListOfRepositoryResources([...res?.data?.data?.resources]);
        setListOfRepositoryChildren([...res?.data?.data?.children]);
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => setResourcesLoad(false));
  };

  const delete_folder = () => {
    setDeleteLoadFolder(true);
    setRepositoryLoader(true);
    if (showDeleteRepository) {
      axiosInstance
        .delete(`repositories/${showDeleteRepository}`)
        .then(res => {
          setListRepositories([...listRepositories.filter(r => r.id !== showDeleteRepository)]);
          dispatch(toast("success", res.data.message));
        })
        .catch(err => {
          dispatch(toast("error", err?.response?.data?.message));
        })
        .finally(() => {
          setDeleteLoadFolder(false);
          setShowDeleteRepository(null);
          setRepositoryLoader(false);
        });
    } else {
      dispatch(toast("error", "No Folder Selected"));
    }
  };

  useEffect(() => {
    activeRepository && getRepositoryResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRepository]);

  useEffect(() => {
    const handleClickOutside = event => {
      // Close the dropdown if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropResource(null);
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropFolder(null);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const handelUploadResource = async event => {
    event.preventDefault();
    setUploadResourcesLoad(true);

    for (let i = 0; i < uploadResourceFiles.length; i++) {
      const resource = uploadResourceFiles[i];

      const formData = new FormData();
      resource.title && formData.append("title", resource.title);
      formData.append("file", resource.file);
      activeRepository?.id && formData.append("activeRepository", activeRepository?.id);

      try {
        const res = await axiosInstance.post("upload/resource", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        activeRepository
          ? setListOfRepositoryResources(prev => [...prev, res.data.resource])
          : setData(prev => [...prev, res.data.resource]);

        setUploadResourceFiles(prevFiles =>
          prevFiles.map(file => (file.id === resource.id ? { ...file, uploaded: true } : file)),
        );
      } catch (err) {
        dispatch(toast("error", err?.response?.data?.message));
      }
    }

    setUploadResourcesLoad(false);
    setUploadResources(false);
    setUploadResourceFiles([]);
  };

  const handelMoveRepToRep = () => {
    setCreateRepositoryLoader(true);
    const data = {
      repository_id: moveRepToRep,
      parent_id: moveToRepository === "/" ? null : moveToRepository,
    };

    axiosInstance
      .post("repositories/move", data)
      .then(res => {
        if (!data.parent_id) {
          const repo = listOfRepositoryChildren?.find(r => r.id === moveRepToRep);
          setListOfRepositoryChildren([...listOfRepositoryChildren?.filter(r => r.id !== moveRepToRep)]);
          setListRepositories([...listRepositories, repo]);
        } else {
          setListRepositories([...listRepositories?.filter(r => r.id !== moveRepToRep)]);
          setListOfRepositoryChildren([...listOfRepositoryChildren?.filter(r => r.id !== moveRepToRep)]);
        }
        dispatch(toast("success", res.data.message));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setDropFolder(null);
        setMoveRepToRep(null);
        setMoveToRepository(null);
        setCreateRepositoryLoader(false);
      });
  };

  const handleChangePath = id => {
    const newPath = path.slice(0, id + 1);
    setPath(newPath);

    const updatedRepository = newPath[newPath.length - 1];
    setActiveRepository(updatedRepository);
  };

  const renameRepository = () => {
    if (!newRepositoryName.trim()) {
      dispatch(toast("error", "Repository name cannot be empty"));
      return;
    }

    setRenameLoader(true);
    axiosInstance
      .put(`repositories/${showRenameRepository.id}`, {
        name: newRepositoryName,
      })
      .then(res => {
        if (activeRepository) {
          const updatedChildren = listOfRepositoryChildren.map(repo =>
            repo.id === res.data.repository.id
              ? res.data.repository // Use the entire repository object from the response
              : repo,
          );
          setListOfRepositoryChildren([...updatedChildren]);

          // Also update the path if we're renaming the current folder
          if (activeRepository.id === res.data.repository.id) {
            const updatedPath = path.map(item => (item.id === res.data.repository.id ? res.data.repository : item));
            setPath(updatedPath);
            setActiveRepository(res.data.repository);
          }
        } else {
          const updatedRepositories = listRepositories.map(repo =>
            repo.id === res.data.repository.id
              ? res.data.repository // Use the entire repository object from the response
              : repo,
          );
          setListRepositories([...updatedRepositories]);
        }

        // Always update allRepositories
        const updatedAllRepositories = allRepositories.map(repo =>
          repo.id === res.data.repository.id
            ? res.data.repository // Use the entire repository object from the response
            : repo,
        );
        setAllRepositories([...updatedAllRepositories]);

        dispatch(toast("success", res.data.message));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setRenameLoader(false);
        setShowRenameRepository(null);
        setNewRepositoryName("");
      });
  };

  const handleSearch = async query => {
    if (query.trim()) {
      await searchResources(query);
      setActiveRepository(null); // Clear active repository when searching
    } else {
      clearSearch();
    }
  };

  return (
    <div>
      <div className={styles.main}>
        {!createResource ? (
          <div className={styles.container}>
            <ResourceHeader
              activeResource={activeResource}
              changeResources={changeResources}
              setActiveRepository={setActiveRepository}
              onSearchSubmit={handleSearch}
              onCreateResource={() => setCreateResource(true)}
              onUploadResource={() => setUploadResources(true)}
              onCreateFolder={() => setShowRepository(true)}
            />

            {isSearching ? (
              <div className={styles.centerLoader}>
                <DotsLoader />
              </div>
            ) : searchResults ? (
              <>
                {searchResults.length > 0 ? (
                  <div className={styles.gridRow}>
                    {searchResults.map((resource, i) => (
                      <ResourceItem
                        key={i}
                        resource={{
                          ...resource,
                          extension: resource.policyDocument.split(".").pop(),
                          name: resource.policyName,
                          repository: resource.repositories?.[0] || null,
                        }}
                        dropResource={dropResource}
                        setDropResource={setDropResource}
                        resourceRef={ressourcesRef}
                        deleteLoad={deleteLoad}
                        actions={resourceActions.filter(action => !action.showIf || action.showIf(resource))}
                        onAction={(type, res) => {
                          const action = resourceActions.find(a => a.type === type);
                          if (action) {
                            action.onClick(res);
                          }
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles.noResults}>
                    <SvgSprite
                      spriteID={"search"}
                      className={styles.bigSearchIcon}
                    />
                    <span>No resources found</span>
                  </div>
                )}
              </>
            ) : (
              <div>
                {activeResource === 0 && (
                  <div className={cn(styles.addBtnResource, { [styles.noPadding]: !activeRepository })}>
                    <div>
                      {activeRepository && (
                        <div className={styles.repositoryPathDiv}>
                          <div
                            className={styles.repositoryPath}
                            onClick={() => {
                              setActiveRepository(null);
                              setListOfRepositoryResources([]);
                            }}
                          >
                            {"Resources "}
                          </div>
                          <div className={styles.repositoryPath}>
                            <pre>{" / "}</pre>
                          </div>
                          {path?.map((item, index) => (
                            <div
                              key={index}
                              className={styles.repositoryPathDiv}
                              onClick={() => handleChangePath(index)}
                            >
                              <div className={styles.repositoryPath}>{item?.name}</div>
                              <div className={styles.repositoryPath}>
                                <pre>{index !== path.length - 1 && " / "}</pre>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeRepository && activeResource === 0 ? (
                  <Repository
                    repository={activeRepository}
                    repositores={listOfRepositoryChildren}
                    resources={listOfRepositoryResources}
                    getExt={getExt}
                    getFile={getFile}
                    getPDF={getFile}
                    dispatch={dispatch}
                    setResources={setListOfRepositoryChildren}
                    setDropResource={setDropResource}
                    dropResource={dropResource}
                    cloneResource={cloneResource}
                    editResource={editResource}
                    downloadFile={downloadFile}
                    delete_resource={delete_resource}
                    deleteLoad={deleteLoad}
                    loader={resourcesLoad}
                    listRepositories={listRepositories}
                    repositoryLoader={repositoryLoader}
                    handelMove={handelMove}
                    setData={setData}
                    data={data}
                    setRepositoryLoader={setRepositoryLoader}
                    setDropFolder={setDropFolder}
                    dropFolder={dropFolder}
                    deleteLoadFolder={deleteLoadFolder}
                    setShowDeleteRepository={setShowDeleteRepository}
                    setActiveRepository={setActiveRepository}
                    setMoveRepToRep={setMoveRepToRep}
                    allRepositories={allRepositories}
                  />
                ) : !repositoryLoader ? (
                  <div className={styles.gridRow}>
                    {activeResource === 0 &&
                      listRepositories?.map((r, i) => (
                        <div
                          key={i}
                          className={cn(styles.gridItem)}
                        >
                          <div className={styles.resourcesHeaderIcon}>
                            <SvgSprite
                              spriteID={"dots"}
                              className={cn(styles.resourcesIcon, styles.dotsStyles)}
                              onClick={() => setDropFolder(dropFolder === r?.id ? null : r?.id)}
                            />
                            {dropFolder === r.id && (
                              <div
                                ref={dropdownRef}
                                className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
                              >
                                <div
                                  className={styles.dropResourceBtn}
                                  onClick={() => {
                                    setShowRenameRepository(r);
                                    setNewRepositoryName(r.name);
                                    setDropFolder(null);
                                  }}
                                >
                                  <div> Rename </div>
                                  <SvgSprite
                                    spriteID={"edit_pen"}
                                    className={styles.resourcesIcon}
                                  />
                                </div>
                                <div
                                  className={cn(styles.dropResourceBtn, {
                                    [styles.desableClick]: listRepositories.length <= 0,
                                  })}
                                  onClick={() => {
                                    setMoveRepToRep(r.id);
                                  }}
                                >
                                  <div> Move </div>
                                  <SvgSprite
                                    spriteID={"move"}
                                    className={styles.resourcesIcon}
                                  />
                                </div>
                                {r?.organization_id && (
                                  <div
                                    className={cn(styles.dropResourceBtn, {
                                      [styles.deleteLoad]: deleteLoad,
                                    })}
                                    onClick={() => {
                                      setShowDeleteRepository(r?.id);
                                      setDropFolder(null);
                                    }}
                                  >
                                    <div> Delete </div>
                                    {deleteLoadFolder ? (
                                      <Loader />
                                    ) : (
                                      <SvgSprite
                                        spriteID={"trash"}
                                        className={styles.resourcesIcon}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            onClick={() => setActiveRepository(r)}
                            className={styles.bodyTypeContainer}
                          >
                            <SvgSprite
                              spriteID={"folder"}
                              className={styles.folderStyles}
                            />
                          </div>
                          <div
                            onClick={() => setActiveRepository(r)}
                            className={styles.centerFrameworkName}
                            title={r.name}
                          >
                            {r.name}
                          </div>
                        </div>
                      ))}

                    {data?.map((r, i) => (
                      <div
                        key={i}
                        className={cn(styles.gridItem)}
                      >
                        <div className={styles.resourcesHeaderIcon}>
                          <SvgSprite
                            spriteID={"dots"}
                            className={cn(styles.resourcesIcon, styles.dotsStyles)}
                            onClick={() => setDropResource(dropResource === r.id ? null : r.id)}
                          />
                          {dropResource === r.id && (
                            <div
                              ref={dropdownRef}
                              className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
                            >
                              {r?.html && (
                                <div
                                  className={styles.dropResourceBtn}
                                  onClick={() => {
                                    cloneResource(r);
                                    setDropResource(null);
                                  }}
                                >
                                  <div> Clone </div>
                                  <SvgSprite
                                    spriteID={"filePaper"}
                                    className={styles.resourcesIcon}
                                  />
                                </div>
                              )}
                              {r?.organization_id && r?.html && (
                                <div
                                  className={styles.dropResourceBtn}
                                  onClick={() => {
                                    editResource(r);
                                    setDropResource(null);
                                  }}
                                >
                                  <div> Edit </div>
                                  <SvgSprite
                                    spriteID={"edit_pen"}
                                    className={styles.resourcesIcon}
                                  />
                                </div>
                              )}
                              <div
                                className={styles.dropResourceBtn}
                                onClick={() => {
                                  downloadFile(r.policyDocument);
                                  setDropResource(null);
                                }}
                              >
                                <div> Download </div>
                                <SvgSprite
                                  spriteID={"download"}
                                  className={styles.resourcesIcon}
                                />
                              </div>
                              <div
                                className={cn(styles.dropResourceBtn, {
                                  [styles.desableClick]: listRepositories.length <= 0,
                                })}
                                onClick={() => {
                                  setShowListRepositoriesPopup(r.id);
                                  setDropResource(null);
                                }}
                              >
                                <div> Move </div>
                                <SvgSprite
                                  spriteID={"move"}
                                  className={styles.resourcesIcon}
                                />
                              </div>
                              {r?.organization_id && (
                                <div
                                  className={cn(styles.dropResourceBtn, {
                                    [styles.deleteLoad]: deleteLoad,
                                  })}
                                  onClick={() => delete_resource(r?.id)}
                                >
                                  <div> Delete </div>
                                  {deleteLoad ? (
                                    <Loader />
                                  ) : (
                                    <SvgSprite
                                      spriteID={"trash"}
                                      className={styles.resourcesIcon}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.bodyTypeContainer}
                          onClick={(e) => {
                            e.preventDefault();
                            if (r.policyDocument) {
                              getFile(r.policyDocument);
                            } else {
                              dispatch(toast("error", "No file available"));
                            }
                          }}
                        >
                          <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                        </div>
                        <div
                          className={styles.centerFrameworkName}
                          onClick={(e) => {
                            e.preventDefault();
                            if (r.policyDocument) {
                              getFile(r.policyDocument);
                            } else {
                              dispatch(toast("error", "No file available"));
                            }
                          }}
                          title={r.policyName}
                        >
                          {r.policyName}
                        </div>
                        <div
                          className={styles.frameworkCategory}
                          onClick={(e) => {
                            e.preventDefault();
                            if (r.policyDocument) {
                              getFile(r.policyDocument);
                            } else {
                              dispatch(toast("error", "No file available"));
                            }
                          }}
                        >
                          {r.category}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.centerLoader}>
                    <DotsLoader />
                  </div>
                )}
                <div className={styles.centerLoader}>
                  {data?.length <= 0 && listRepositories?.length === 0 && (
                    <div className={styles.noResource}> No resources </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.resourceHeader}>
              <div className={styles.resourceHeaderLeft}>
                <div
                  className={cn(styles.btnResource, {
                    [styles.active]: activeResource === 0,
                  })}
                  onClick={() => {
                    location.state ? navigate("/help") : setCreateResource(false);
                    setResource("");
                    setHtml("");
                    title.setNewValue("");
                    setUpdate(false);
                  }}
                >
                  <SvgSprite
                    spriteID={"arrowNext"}
                    className={styles.textHeaderLeftIcon}
                  />
                </div>
                <div> Create A Company Resources </div>
              </div>
              <div className={styles.btnResource}>
                <button
                  className={styles.btnBlue}
                  type="button"
                  onClick={() => {
                    pdfPreview ? setPdfPreview(false) : handleGeneratePdf();
                  }}
                >
                  <SvgSprite spriteID={pdfPreview ? "filePaper" : "eyePassword"} />
                  <div className={styles.textHeaderReigthText}>{pdfPreview ? "return" : "Preview"}</div>
                </button>
                <button
                  className={styles.btnBlue}
                  type={"button"}
                  onClick={() => {
                    update ? updateResource() : saveResource();
                  }}
                  disabled={loadSave}
                >
                  {loadSave ? <Loader /> : <SvgSprite spriteID={"filePaper"} />}
                  <div className={styles.textHeadersave}>{update ? "Update" : "Save"}</div>
                </button>
              </div>
            </div>
            <div className={styles.reportFormBodyLeft}>
              <div className={styles.titleReport}>
                <div className={styles.titleReportLeft}> Title </div>
                <div className={styles.titleReportReight}>
                  <Input
                    error={error.title}
                    className={styles.inputStyle}
                    onChange={e => title.onChange(e)}
                    value={title.value || ""}
                  />
                </div>
              </div>
              <div className={styles.cardContent}>
                <Card
                  editorRef={editorRef}
                  html={html}
                  Header={"Resource Content"}
                  data={resource}
                  setData={setResource}
                  error={error.pdf}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Create Repository */}
      <Popup
        small={true}
        isOpenedPopup={showRepository}
        closePopup={() => {
          setShowRepository(false);
        }}
      >
        <div className={styles.divRepository}>
          <input
            className={styles.repositoryInput}
            placeholder="Create Repository"
            onChange={e => setRepositoryName(e.target.value)}
          />
          <Btn
            disable={createRepositoryLoader}
            className={styles.repositoryBtn}
            onClick={handelGenerateRepository}
          >
            {createRepositoryLoader ? <Loader /> : "Create Repository"}
          </Btn>
        </div>
      </Popup>

      {/* Upload Resources */}
      <Popup
        small={true}
        isOpenedPopup={uploadResources}
        closePopup={() => {
          setUploadResources(false);
          setUploadResourceFiles([]);
        }}
      >
        <form
          className={styles.divRepository}
          onSubmit={e => handelUploadResource(e)}
        >
          {uploadResourceFiles.length > 0 &&
            uploadResourceFiles.map((item, index) => (
              <div
                key={item.file.name + index}
                className={styles.inputWrapper}
              >
                <input
                  type="text"
                  placeholder="Enter resource name"
                  value={uploadResourceFiles[index].title || ""}
                  onChange={e => {
                    const newTitles = [...uploadResourceFiles];
                    newTitles[index].title = e.target.value;
                    setUploadResourceFiles(newTitles);
                  }}
                  className={styles.repositoryInput}
                />
                <span className={styles.iconWrapper}>
                  <SvgSprite
                    className={item.uploaded ? styles.checkIconChecked : styles.checkIcon}
                    spriteID={"check"}
                  />
                  <span
                    className={styles.deleteIcon}
                    onClick={() => {
                      const filtered = uploadResourceFiles.filter(resource => item.id !== resource.id);
                      setUploadResourceFiles(filtered);
                    }}
                  >
                    ❌
                  </span>
                </span>
              </div>
            ))}
          <div className={styles.upload}>
            <div className={styles.formUp}>
              <input
                required
                type="file"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.csv,.xlsx,.xls"
                name="resources"
                multiple
                onChange={e => {
                  const filesArray = Array.from(e.target.files).map(file => ({
                    id: uuidv4(),
                    file,
                    title: file.name.slice(0, file.name.lastIndexOf(".")),
                    uploaded: false,
                  }));

                  setUploadResourceFiles(filesArray);
                }}
              />
              <div className={styles.customUploadBtn}>Browse Files</div>
            </div>
          </div>

          <Btn
            type={"submit"}
            disable={repositoryLoader}
            className={styles.repositoryBtn}
          >
            {uploadResourcesLoad ? <Loader /> : "Upload Resources"}
          </Btn>
        </form>
      </Popup>

      <Popup
        small={true}
        isOpenedPopup={showDeleteRepository}
        closePopup={() => {
          setShowDeleteRepository(null);
        }}
      >
        <div className={styles.divRepository}>
          <p className={styles.repoMessage}>
            Are you sure you want to delete this repository and its associated resources? <br />
            <br /> <span className={styles.spanRepoMessage}>This action cannot be undone.</span>
          </p>
          <div className={styles.repoBtns}>
            <Btn
              disable={repositoryLoader}
              className={styles.repositoryBtn}
              onClick={() => setShowDeleteRepository(null)}
            >
              Cancel
            </Btn>
            <Btn
              disable={repositoryLoader}
              className={cn(styles.repositoryBtn, styles.repoDeleteBtn)}
              onClick={() => delete_folder()}
            >
              {repositoryLoader ? <Loader /> : "Delete Repository"}
            </Btn>
          </div>
        </div>
      </Popup>

      <Popup
        small={true}
        isOpenedPopup={showListRepositoriesPopup}
        closePopup={() => {
          setShowListRepositoriesPopup(false);
          setMoveToRepository(null);
        }}
      >
        <div className={styles.repositoriesContainer}>
          <div className={styles.listOfRepositories}>
            {allRepositories?.map((r, i) => (
              <div
                key={i}
                className={styles.radioRepository}
                onClick={() => setMoveToRepository(r.id)}
              >
                <input
                  type="radio"
                  name="repo"
                  checked={r.id === moveToRepository}
                  className={styles.repositoryInputRadio}
                  placeholder="Create Repository"
                  onChange={() => setMoveToRepository(r.id)}
                />
                {r.name}
              </div>
            ))}
            {allRepositories.length === 0 && <div className={styles.NoRepoMessage}> No Repositories </div>}
          </div>
          <Btn
            disable={createRepositoryLoader}
            className={styles.repositoryBtn}
            onClick={handelMove}
          >
            {createRepositoryLoader ? <Loader /> : "Move"}
          </Btn>
        </div>
      </Popup>

      <Popup
        small={true}
        isOpenedPopup={moveRepToRep}
        closePopup={() => {
          setMoveRepToRep(null);
          setMoveToRepository(null);
        }}
      >
        <div className={styles.repositoriesContainer}>
          <div className={styles.listOfRepositories}>
            {activeRepository && (
              <div
                className={styles.radioRepository}
                onClick={() => setMoveToRepository("/")}
              >
                <input
                  type="radio"
                  name="repo"
                  checked={"/" === moveToRepository}
                  className={styles.repositoryInputRadio}
                  placeholder="Create Repository"
                  onChange={() => setMoveToRepository("/")}
                />
                {"/"}
              </div>
            )}
            {allRepositories?.map(
              (r, i) =>
                moveRepToRep !== r.id && (
                  <div
                    key={i}
                    className={styles.radioRepository}
                    onClick={() => setMoveToRepository(r.id)}
                  >
                    <input
                      type="radio"
                      name="repo"
                      checked={r.id === moveToRepository}
                      className={styles.repositoryInputRadio}
                      placeholder="Create Repository"
                      onChange={() => setMoveToRepository(r.id)}
                    />
                    {r.name}
                  </div>
                ),
            )}
            {allRepositories.length === 0 && <div className={styles.NoRepoMessage}> No Repositories </div>}
          </div>
          <Btn
            disable={createRepositoryLoader}
            className={styles.repositoryBtn}
            onClick={() => handelMoveRepToRep()}
          >
            {createRepositoryLoader ? <Loader /> : "Move"}
          </Btn>
        </div>
      </Popup>

      <Popup
        small={true}
        isOpenedPopup={showRenameRepository}
        closePopup={() => {
          setShowRenameRepository(null);
          setNewRepositoryName("");
        }}
      >
        <div className={styles.divRepository}>
          <input
            className={styles.repositoryInput}
            placeholder="Repository name"
            value={newRepositoryName}
            onChange={e => setNewRepositoryName(e.target.value)}
            autoFocus
          />
          <Btn
            disable={renameLoader}
            className={styles.repositoryBtn}
            onClick={renameRepository}
          >
            {renameLoader ? <Loader /> : "Rename Repository"}
          </Btn>
        </div>
      </Popup>
    </div>
  );
};

const Card = ({ Header, data, setData, error, editorRef, html }) => {
  return (
    <div className={styles.controlCardPopup}>
      <div className={styles.controlCardHeaderPopup}>
        <div className={styles.controlCardHeaderLeftPopup}>
          <div>{Header}</div>
        </div>
        <div className={styles.controlCardHeaderReigthPopup}>
          <SvgSprite
            spriteID={"arrow"}
            className={styles.arrowInverst}
          />
        </div>
      </div>
      <div className={styles.controlCardBodyPopup}>
        <TextEditor
          editorRef={editorRef}
          setData={setData}
          data={data || html}
          placeholder={Header}
          error={error}
        />
      </div>
    </div>
  );
};
